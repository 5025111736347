/**
 * Form validation for forms with checkboxes.
 *
 * Disables the submit button if no checkboxes are checked.
 * @param {HTMLFormElement} form - The form element to validate
 */
function validateForm(form) {
  for (
    const submitButton of document.querySelectorAll(
      "button[type=submit], input[type=submit]",
    )
  ) {
    submitButton.disabled = !Array.from(form.querySelectorAll("input[type=checkbox]"))
      .some((interest) => {
        return interest.checked
      })
    document.getElementById("form-require-text").style.display = submitButton.disabled
      ? "block"
      : "none"
  }
}

/**
 * Sets up change event listeners with checkbox validation for a form.
 * @param {HTMLFormElement} form - The form element to validate
 */
export function setupFormValidation(form) {
  for (const element of form.elements) {
    element.addEventListener("change", () => validateForm(form))
  }
  validateForm(form)
}

/**
 * Shows a dialog when the related life situation is selected.
 * Unselect all kids checkboxes when the life situation is unselected.
 * @param {object} event - The event object
 * @param {Array} kidsCheckboxes - checkboxes for kids choices
 * @param {HTMLDialogElement} dialog - dialog element containing the kids choices
 */
function checkKidsSelection(event, kidsCheckboxes, dialog) {
  if (event.target.checked) {
    dialog.showModal()
  } else {
    kidsCheckboxes.forEach((checkbox) => {
      checkbox.checked = false
      checkbox.dispatchEvent(new Event("change"))
    })
  }
}

/**
 * Sets up kids selection dialog.
 *
 * Adds event listener to the trigger element to show the dialog when the trigger is checked.
 * @param {string} dialogId - The id of the dialog element
 * @param {string} triggerId - The id of the trigger element
 * @param {string} kidsChoiceWrapperId - The id of the kids choice wrapper element
 */
export function setupUpKidsSelection(dialogId, triggerId, kidsChoiceWrapperId) {
  const dialog = document.getElementById(dialogId)
  const trigger = document.getElementById(triggerId)
  const kidsCheckboxes = document.getElementById(kidsChoiceWrapperId).querySelectorAll(
    'input[type="checkbox"]',
  )
  trigger.addEventListener(
    "change",
    (event) => checkKidsSelection(event, kidsCheckboxes, dialog),
  )
}
